import { TakeoverAd } from 'js/components/specific/top/TakeoverAd/type';
import { EcnFrames } from 'js/const/ecnFrames';

export const TAKEOVER_AD_MAP: Record<string, TakeoverAd> = {
  // ループで処理できないため、1~4件目のみ有効とする
  olieve_202409: {
    linkUrl: `/ad/10707549/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Olive口座開設',
    headerBannerUrl: '/top/img/takeover/olieve_202409_header.png',
    firstSideBannerUrl: '/top/img/takeover/olieve_202409_side1.png',
    secondSideBannerUrl: '/top/img/takeover/olieve_202409_side2.png',
    background: '#004831',
  },
  orbisu_trial_20240922: {
    linkUrl: `/ad/17605/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'オルビスユー トライアルセット',
    headerBannerUrl: '/top/img/takeover/orbisu_trial_202409_header.jpg',
    firstSideBannerUrl: '/top/img/takeover/orbisu_trial_202408_side.jpg',
    secondSideBannerUrl: '/top/img/takeover/orbisu_trial_202408_side.jpg',
    background:
      'linear-gradient(rgb(187 204 214), rgb(235 247 255) 2000px, rgb(242 246 249) 4000px)',
  },
  orbis_clearful_20240918: {
    linkUrl: `/ad/17606/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'オルビス クリアフル',
    headerBannerUrl: '/top/img/takeover/orbis_clearful_202409_header.jpg',
    firstSideBannerUrl: '/top/img/takeover/orbis_clearful_202408_side.jpg',
    secondSideBannerUrl: '/top/img/takeover/orbis_clearful_202408_side.jpg',
    background: '#004c9d',
  },
  orbisu_dot_20240914: {
    linkUrl: `/ad/58188/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'オルビスユー ドット',
    headerBannerUrl: '/top/img/takeover/orbisu_dot_202409_header.jpg',
    firstSideBannerUrl: '/top/img/takeover/orbisu_dot_202408_side.jpg',
    secondSideBannerUrl: '/top/img/takeover/orbisu_dot_202408_side.jpg',
    background:
      'linear-gradient(rgb(245 222 207), rgb(217 204 217) 2000px, rgb(44 48 83) 4000px)',
  },
};
